import React, { useState, useRef, MouseEvent } from 'react'
import classNames from 'classnames/bind'

import SEO from 'components/core/SEO'
import TrailButton from 'components/ui/TrailButton'
import Tagline from 'components/ui/Tagline'
import Clickable from 'components/ui/Clickable'

import * as s from './NotFound.module.scss'
const cn = classNames.bind(s)

const NotFound = () => {
  const [isMouseOver, setIsMouseOver] = useState(false)
  const mouse = useRef({ x: 0, y: 0 }).current

  const onMouseMove = (event: MouseEvent) => {
    mouse.x = event.clientX
    mouse.y = event.clientY
  }

  return (
    <>
      <SEO title='Page not found' />
      <div
        className={cn('wrapper')}
        onMouseMove={onMouseMove}
        onMouseEnter={() => setIsMouseOver(true)}
        onMouseLeave={() => setIsMouseOver(false)}
      >
        <Tagline tagline='Page is not found.' marqueeSpeed={45} renderLoaderUI={false} />
        <Tagline tagline='Go home. Go home.' marqueeSpeed={40} renderLoaderUI={false} />
        <Tagline tagline='Page is not found.' marqueeSpeed={30} renderLoaderUI={false} />

        <TrailButton
          text='Go Home'
          background='linear-gradient(36.38deg, #F969FC 14.52%, #BC01F0 38.16%, #0C01DE 85.21%)'
          invertedText
          mouse={mouse}
          isMouseOver={isMouseOver}
          url={'/'}
          mobileClass={cn('mobileButton')}
          mobileAnimationDelay={1.5}
        />
        <Clickable to={'/'} className={cn('link')}>
          <span>Go home.</span>
        </Clickable>
      </div>
    </>
  )
}

export default NotFound
